(function ($) {

	var $popup = $('.js-popup')

	$popup.magnificPopup({
        type: 'inline',
        fixedContentPos: true,
        fixedBgPos: true,
        overflowY: 'hidden',
        closeBtnInside: true,
        autoFocusLast: true,
        showCloseBtn: true,
        preloader: false,
        midClick: true,
        removalDelay: 300,
        mainClass: 'my-mfp-zoom-in',
    });

})(jQuery);

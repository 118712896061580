(function ($) {
    
    var progressBar_1 = '#js-progress-bar-1',
        progressBarPercent_1 = '30';
    
    var progressBar_2 = '#js-progress-bar-2',
        progressBarPercent_2 = '30';
    
    var progressBar_3 = '#js-progress-bar-3',
        progressBarPercent_3 = '18';
    
    var progressBar_4 = '#js-progress-bar-4',
        progressBarPercent_4 = '14';
    
    if (
        $(progressBar_1).length > 0 &&
        $(progressBar_2).length > 0 &&
        $(progressBar_3).length > 0 &&
        $(progressBar_4).length > 0
    ) {
        
        var bar_1 = new ProgressBar.Line(progressBar_1, {
            strokeWidth: 4,
            easing: 'easeInOut',
            duration: 1400,
            color: 'rgb(255, 145, 0)',
            trailColor: '#edeff2',
            trailWidth: 1,
            svgStyle: { width: progressBarPercent_1 + '%', height: '5px' },
            text: {
                style: {
                    color: '#58468c',
                    position: 'absolute',
                    right: '0',
                    top: '-30px',
                    padding: 0,
                    margin: 0,
                    transform: null
                },
                autoStyleContainer: false
            },
            step: (state, bar) => {
                bar.setText(Math.round(bar.value() * progressBarPercent_1) + '%');
            }
        });

        var bar_2 = new ProgressBar.Line(progressBar_2, {
            strokeWidth: 4,
            easing: 'easeInOut',
            duration: 1400,
            color: '#f47920',
            trailColor: '#edeff2',
            trailWidth: 1,
            svgStyle: { width: progressBarPercent_2 + '%', height: '5px' },
            text: {
                style: {
                    color: '#58468c',
                    position: 'absolute',
                    right: '0',
                    top: '-30px',
                    padding: 0,
                    margin: 0,
                    transform: null
                },
                autoStyleContainer: false
            },
            step: (state, bar) => {
                bar.setText(Math.round(bar.value() * progressBarPercent_2) + '%');
            }
        });

        var bar_3 = new ProgressBar.Line(progressBar_3, {
            strokeWidth: 4,
            easing: 'easeInOut',
            duration: 1400,
            color: 'rgb(255, 145, 0)',
            trailColor: '#edeff2',
            trailWidth: 1,
            svgStyle: { width: progressBarPercent_3 + '%', height: '5px' },
            text: {
                style: {
                    color: '#58468c',
                    position: 'absolute',
                    right: '0',
                    top: '-30px',
                    padding: 0,
                    margin: 0,
                    transform: null
                },
                autoStyleContainer: false
            },
            step: (state, bar) => {
                bar.setText(Math.round(bar.value() * progressBarPercent_3) + '%');
            }
        });

        var bar_4 = new ProgressBar.Line(progressBar_4, {
            strokeWidth: 4,
            easing: 'easeInOut',
            duration: 1400,
            color: '#f47920',
            trailColor: '#edeff2',
            trailWidth: 1,
            svgStyle: { width: progressBarPercent_4 + '%', height: '5px' },
            text: {
                style: {
                    color: '#58468c',
                    position: 'absolute',
                    right: '0',
                    top: '-30px',
                    padding: 0,
                    margin: 0,
                    transform: null
                },
                autoStyleContainer: false
            },
            step: (state, bar) => {
                bar.setText(Math.round(bar.value() * progressBarPercent_4) + '%');
            }
        });
    
        var $jsInitAnim = $('#js-industries-section');
        
        $(window).scroll(function () {
            var hT = $jsInitAnim.offset().top,
                hH = $jsInitAnim.outerHeight(),
                wH = $(window).height(),
                wS = $(this).scrollTop();
            
            if (wS > (hT + hH - wH) - 100) {
                bar_1.animate(1.0);
                bar_2.animate(1.0);
                bar_3.animate(1.0);
                bar_4.animate(1.0);
            }
        });
    }

    /**
     * Achievements
     **/
    var progressBar_achievements_1 = '#js-progress-bar-achievements-1',
        progressBarPercent_achievements_1 = '60';
    
    var progressBar_achievements_2 = '#js-progress-bar-achievements-2',
        progressBarPercent_achievements_2 = '100';
    
    var progressBar_achievements_3 = '#js-progress-bar-achievements-3',
        progressBarPercent_achievements_3 = '92';
    
    if ($(progressBar_achievements_1).length > 0) {
        
        var bar_achievements_1 = new ProgressBar.Line(progressBar_achievements_1, {
            strokeWidth: 4,
            easing: 'easeInOut',
            duration: 1400,
            color: 'rgb(255, 145, 0)',
            trailColor: '#edeff2',
            trailWidth: 1,
            svgStyle: { width: progressBarPercent_achievements_1 + '%', height: '5px' },
            text: {
                style: {
                    color: '#58468c',
                    position: 'absolute',
                    right: '0',
                    top: '-30px',
                    padding: 0,
                    margin: 0,
                    transform: null
                },
                autoStyleContainer: false
            },
            step: (state, bar) => {
                bar.setText(Math.round(bar.value() * progressBarPercent_achievements_1) + '%');
            }
        });

        bar_achievements_1.animate(1.0);
    }

    if ($(progressBar_achievements_2).length > 0) {
        var bar_achievements_2 = new ProgressBar.Line(progressBar_achievements_2, {
            strokeWidth: 4,
            easing: 'easeInOut',
            duration: 1400,
            color: '#f47920',
            trailColor: '#edeff2',
            trailWidth: 1,
            svgStyle: { width: progressBarPercent_achievements_2 + '%', height: '5px' },
            text: {
                style: {
                    color: '#58468c',
                    position: 'absolute',
                    right: '0',
                    top: '-30px',
                    padding: 0,
                    margin: 0,
                    transform: null
                },
                autoStyleContainer: false
            },
            step: (state, bar) => {
                bar.setText(Math.round(bar.value() * progressBarPercent_achievements_2) + '%');
            }
        });

        bar_achievements_2.animate(1.0);
    }

    if ($(progressBar_achievements_3).length > 0) {
        var bar_achievements_3 = new ProgressBar.Line(progressBar_achievements_3, {
            strokeWidth: 4,
            easing: 'easeInOut',
            duration: 1400,
            color: 'rgb(255, 145, 0)',
            trailColor: '#edeff2',
            trailWidth: 1,
            svgStyle: { width: progressBarPercent_achievements_3 + '%', height: '5px' },
            text: {
                style: {
                    color: '#58468c',
                    position: 'absolute',
                    right: '0',
                    top: '-30px',
                    padding: 0,
                    margin: 0,
                    transform: null
                },
                autoStyleContainer: false
            },
            step: (state, bar) => {
                bar.setText(Math.round(bar.value() * progressBarPercent_achievements_3) + '%');
            }
        });

        bar_achievements_3.animate(1.0);
    }
    
})(jQuery);

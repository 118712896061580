(function ($) {

    /**
     * Generacja spisu treści
     **/
    $('.js-wysiwyg-content,.js-section-content').find('h3,h2').each(function () {
        var titles = [];

        if (titles == '') {
            titles = $(this).text().replace(/[:]/g, "");
            var href = titles.replace(/[^a-zA-Z0-9\-]/g, '-').replace(/[-]{2,}/gim, '-').replace(/^\-+/g, '').replace(/\-+$/g, '').toLowerCase();
            $(this).prop('id', href);
            $('.js-contents-list').append('<li class="contents__list-item">' +
                '<a href="#'+href+'" class="contents__list-link js-scroll-link">'+titles+'</a>'    
                + '</li>');
        }
    })

    $(document).ready(function () {
        $('.js-scroll-link').on('click', function(e) {
            e.preventDefault()
            $('html, body').animate({
                scrollTop: $($(this).attr('href')).offset().top - 80,
              },500, 'linear')
        })
    });

})(jQuery);
  
(function ($) {
    
    /**
     * Video modal 
     */
    var $headerSticky = $('.js-header-nav-sticky');
    
    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
        if (scroll >= 500) {
            $headerSticky.addClass('appear');
        } else { 
            $headerSticky.removeClass('appear');
        }
    });

})(jQuery);

(function ($) {
        
    /**
     * Carousel clients
     */
    var $clientsCarousel = $('#js-clients-carousel');

    /**
     * Init slick
     **/
    $clientsCarousel.slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1000,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1240,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            
        ]
    });

})(jQuery);
(function ($) {

    /**
     * Responsive menu
     */
    var $burger = $('#burger'),
        $collapse = $('#js-collapse');
    
    $burger.click(function () {
        $(this).toggleClass('burger-icon--open');

        if ($collapse.hasClass('header-collapse--open')) {
            $collapse.addClass('header-collapse--closed');
            $('body').removeClass('no-scroll');

            setTimeout(function () {
                $collapse.removeClass('header-collapse--closed').removeClass('header-collapse--open');
            }, 250);

        } else {
            $collapse.addClass('header-collapse--opened');
            $('body').addClass('no-scroll');

            setTimeout(function () {
                $collapse.removeClass('header-collapse--opened').addClass('header-collapse--open');
            }, 250);
        }
    });

    // function calcHeightMenuMobile() {
    //     $('#js-collapse').innerHeight( $(this).innerHeight() );
    // }
    
    // calcHeightMenuMobile();
    //   $(window).on('load resize orientationchange', function() {
    //     calcHeightMenuMobile();
    // });

})(jQuery);

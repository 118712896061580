(function ($) {
    var $btn = $('.js-scrolltop'),
        btnShowClass = 'scrolltop--show';
    
    $(document).ready(function () {
        $btn.on('click', function(e) {
            e.preventDefault()
            $('html, body').animate({
                scrollTop: $($(this).attr('href')).offset().top,
              },500, 'linear')
        })
    });
    
    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
        if (scroll >= 500) {
            $btn.addClass(btnShowClass);
        } else { 
            $btn.removeClass(btnShowClass);
        }
    });

})(jQuery);
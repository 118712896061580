(function ($) {

    let $partnerButton = $('.js-drop');
    let $partnerDesc = $('.js-desc');
    $partnerButton.on('click', function () { 
        console.log('czy to w ogole dziala');
        if($(this).hasClass('partners--active')){
            console.log('if test');
            $('.client-visible').removeClass('.client-visible');
            $('.partners--active').removeClass('.partners--active');
        } else {
            console.log('else');
            $('.client-visible').removeClass('.client-visible');
            $('.partners--active').removeClass('.partners--active');
            $(this).addClass('partners--active'); 
            $(this).next($partnerDesc).addClass('client-visible');
        }

    });


})(jQuery);
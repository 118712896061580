(function ($) {

    $(document).ready(function ($) {
		$('#js-hover-box-percentage-1').circleProgress({
            value: 1,
            size: 190,
            startAngle: 4.71,
            fill: { color: '#ff9100' },
            emptyFill: '#f7f7f7',
            thickness: 10
             
        }).on('circle-animation-progress', function(event, progress) {
            $(this).find('#js-hover-box-percentage-num-1').html(Math.round(100 * progress));
        });
    
        $('#js-hover-box-percentage-2').circleProgress({
            value: 0.7,
            size: 190,
            startAngle: 4.71,
            fill: { color: '#cd202c' },
            emptyFill: '#f7f7f7',
            thickness: 10
             
        }).on('circle-animation-progress', function(event, progress) {
            $(this).find('#js-hover-box-percentage-num-2').html(Math.round(60 * progress));
        });
    
        $('#js-hover-box-percentage-3').circleProgress({
            value: 0.8,
            size: 190,
            startAngle: 4.71,
            fill: { color: '#952d98' },
            emptyFill: '#f7f7f7',
            thickness: 10
             
        }).on('circle-animation-progress', function(event, progress) {
            $(this).find('#js-hover-box-percentage-num-3').html(Math.round(70 * progress));
        });
	});
})(jQuery);

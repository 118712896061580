/**
 * Carousel slider
 */
var delay = 3000,
    sliderRadios = document.getElementsByName('carousel-3d'),
    index = 0,
    imageCount = sliderRadios.length;

if (sliderRadios.length) {
    setInterval(function () {
        index++;
        if (index > imageCount - 1) {
            index = 0;
        }
        sliderRadios[index].click();
    }, delay);
}
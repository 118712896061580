(function ($) {
    /**
     * powiązane ze scrollem
     */

    var filterCheckbox = '.js-filter-checkbox'

    $(filterCheckbox).on('change', function() {
        $that = $(this)
        $parent = $that.parent()
        $grandparent = $that.parent().parent().parent()

        if ($that.is(':checked')) {
            $parent.find(filterCheckbox).each(function(){
                $(this).prop('checked', true)
            })
        }
        else {
            $parent.find(filterCheckbox).each(function(){
                $(this).prop('checked', false)
                $grandparent.find(filterCheckbox).first().prop('checked', false)
            })
        }
    })

    var $form = $('#js-shop-filter')
    var $results = $('#js-shop-query-results')
    var $select = $('#js-orderby-select')

    $select.change(function(e) {
        e.preventDefault()
        $form_data = $form.serializeArray()
        if ($select.val() != 'menu_order') {
            $form_data.push({name: 'orderby', value: $select.val()})
        }
        $results.addClass('loading')
        _ajaxLoad($form_data)
    })

    $form.submit(function(e) {
        e.preventDefault()
        $form_data = $form.serializeArray()
        if ($select.val() != 'menu_order') {
            $form_data.push({name: 'orderby', value: $select.val()})
        }
        $results.addClass('loading')
        _ajaxLoad($form_data)
    })

    function _ajaxLoad(formdata) {
        $.ajax({
            url:ajax.url,
            data: {
                action: 'load_products',
                form: formdata,
            },
            type:'post',
            beforeSend: function() {
                $results.addClass('loading')
            },
            success:function(data){
                data = JSON.parse(data)
                //results 
                $results.html(data.view)

                //parse url
                var url = window.location.href
                var parse_url = url.substring(0, url.indexOf('page/'))
                history.pushState(null, '', parse_url + '?' + data.url)

                //remove loading
                $results.removeClass('loading')
                var scrollOffset = $results.offset().top - 220
                if ($(window).width() < 768) {
                    scrollOffset = scrollOffset + 40
                }

                $('html, body').animate({scrollTop: scrollOffset}, 'slow')
            },
            error:function(data){
                $results.removeClass('loading')
            }
        });
        return false;
    }

    $(function () {
        var max_price = $('#max_price').val();
        $('#js-shop-filter-range').slider({
            range: true,
            min: 0,
            max: max_price,
            values: [0, max_price],
            slide: function (event, ui) {
                $('#js-shop-filter-amount').html(ui.values[0] + 'zł' + " - " + ui.values[1] + 'zł');
                $('#price_from').val(ui.values[0]);
                $('#price_to').val(ui.values[1]);
            }
        });
      
        $('#js-shop-filter-amount').html($('#js-shop-filter-range').slider('values', 0) + 'zł'
            + ' - ' + $('#js-shop-filter-range').slider('values', 1) + 'zł');

    });
    
})(jQuery);
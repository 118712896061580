(function ($) {

    AOS.init({
        once: true,
        disable: 'mobile'
    });

    // jQuery test
    if (window.jQuery) {
        console.log('jQuery is working...');
    }

    // ajax variables test
    console.log("ajax.url:", ajax.url);
    console.log("ajax.nonce:", ajax.nonce);
    
})(jQuery);
(function ($) {
    
    /**
     * Video modal 
     */
    var $jsVideoButton = $('.js-video-button'),
        $jsVideoButtonBody = $('.js-video-modal-body'),
        $jsVideoModalOverlay = $('.js-video-modal-overlay'),
        $jsVideoModal = $('.js-video-modal'),
        player = '.player';
    
    $jsVideoButton.on('click', function () { 
        $(this).next().next().addClass('show');
        var $modal = $(this).next();
        setTimeout(function () {
            $modal.addClass('show');
        }, 1000);
    })

    $jsVideoButtonBody.on('click', function () { 
        $jsVideoModal.removeClass('show');
        $jsVideoModalOverlay.removeClass('show');
        $(this).find(player).each(function(index) {
            $(this).attr('src', $(this).attr('src'));
            return false;
        });

    })

})(jQuery);
